var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-row',{staticClass:"_page-content",attrs:{"gutter":10}},[_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-card',{attrs:{"bordered":false}},[_c('div',{staticStyle:{"margin-left":"14px"}},[_c('a-button',{attrs:{"type":"primary","icon":"plus-square"},on:{"click":function($event){return _vm.handleAdd(2)}}},[_vm._v(" 添加部门")]),_vm._v("  "),_c('a-button',{attrs:{"type":"danger"},on:{"click":_vm.handleTopDelete}},[_vm._v(" 删除")]),_vm._v("  "),_c('a-button',{attrs:{"type":"default","icon":"reload","loading":_vm.loading},on:{"click":_vm.refresh}},[_vm._v("刷新")])],1),_c('div',{staticStyle:{"background":"#fff","padding-left":"16px","height":"100%","margin-top":"5px"}},[_c('a-alert',{attrs:{"type":"info","showIcon":true}},[_c('div',{attrs:{"slot":"message"},slot:"message"},[_vm._v(" 当前选择： "),(this.currSelected.deptName)?_c('span',{staticStyle:{"color":"black"}},[_vm._v(_vm._s(_vm.getCurrSelectedTitle()))]):_vm._e(),(this.currSelected.deptName)?_c('a',{staticStyle:{"margin-left":"10px","color":"#1890ff"},on:{"click":_vm.onClearSelected}},[_vm._v("取消选择")]):_vm._e()])]),_c('a-input-search',{staticStyle:{"width":"100%","margin-top":"10px"},attrs:{"placeholder":"请输入部门名称"},on:{"search":_vm.onSearch}}),_c('a-row',[[_c('a-dropdown',{attrs:{"trigger":[this.dropTrigger]},on:{"visibleChange":_vm.dropStatus}},[_c('span',{staticStyle:{"user-select":"none"}},[(!_vm.loading)?_c('a-tree',{ref:"tree2",staticStyle:{"height":"50vh","overflow-x":"hidden","overflow-y":"auto"},attrs:{"multiple":"","selectedKeys":_vm.selectedKeys,"load-data":_vm.onLoadData,"checkedKeys":_vm.checkedKeys,"treeData":_vm.departTree,"checkStrictly":true,"expandedKeys":_vm.iExpandedKeys,"replace-fields":{
                    children: 'children',
                    key: 'id',
                    value: 'deptName',
                    title: 'deptName',
                  },"autoExpandParent":_vm.autoExpandParent},on:{"select":_vm.onSelect,"check":_vm.onCheck,"rightClick":_vm.rightHandle,"expand":_vm.onExpand}}):_vm._e()],1),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',{key:"1",on:{"click":function($event){return _vm.handleAdd(3)}}},[_vm._v("添加")]),_c('a-menu-item',{key:"2",on:{"click":_vm.handleDelete}},[_vm._v("删除")]),_c('a-menu-item',{key:"3",on:{"click":_vm.closeDrop}},[_vm._v("取消")])],1)],1)]],2)],1)])],1),_c('a-col',{attrs:{"md":12,"sm":24}},[_c('a-card',{staticStyle:{"padding-top":"84px"},attrs:{"title":"编辑","bordered":false}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"label":"部门名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'deptName',
              {
                rules: [{ required: true, message: '请输入部门/部门名称!' }],
              },
            ]),expression:"[\n              'deptName',\n              {\n                rules: [{ required: true, message: '请输入部门/部门名称!' }],\n              },\n            ]"}],attrs:{"placeholder":"请输入部门/部门名称"}})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],attrs:{"labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"label":"上级部门"}},[_c('a-tree-select',{staticStyle:{"width":"100%"},attrs:{"dropdownStyle":{maxHeight:'200px',overflow:'auto'},"treeData":_vm.treeData,"placeholder":"","disabled":true},model:{value:(_vm.model.deptName),callback:function ($$v) {_vm.$set(_vm.model, "deptName", $$v)},expression:"model.deptName"}})],1),_c('a-form-item',{attrs:{"labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"label":"排序"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'deptOrder',
              { rules: [{ required: true, message: '请输入排序' }] },
            ]),expression:"[\n              'deptOrder',\n              { rules: [{ required: true, message: '请输入排序' }] },\n            ]"}]})],1),_c('a-form-item',{attrs:{"labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"label":"备注"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'remark',
              { rules: [{ required: false, message: '请输入部门编码!' }] },
            ]),expression:"[\n              'remark',\n              { rules: [{ required: false, message: '请输入部门编码!' }] },\n            ]"}],attrs:{"placeholder":"备注"}})],1)],1),_c('div',{staticClass:"anty-form-btn",staticStyle:{"float":"right"}},[_c('a-button',{attrs:{"type":"default","htmlType":"button","icon":"sync"},on:{"click":_vm.emptyCurrForm}},[_vm._v("重置")]),_vm._v("  "),_c('a-button',{attrs:{"type":"primary","htmlType":"button","icon":"form"},on:{"click":_vm.submitCurrForm}},[_vm._v("修改并保存")])],1)],1)],1),_c('depart-modal',{ref:"departModal",on:{"ok":_vm.loadTree,"refresh":_vm.refresh}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }