<template>
  <a-row :gutter="10" class="_page-content">
    <a-col :md="12" :sm="24">
      <a-card :bordered="false">
        <!-- 按钮操作区域 -->
        <div style="margin-left: 14px">
          <a-button @click="handleAdd(2)" type="primary" icon="plus-square">
            添加部门</a-button
          >&nbsp;
          <a-button @click="handleTopDelete" type="danger"> 删除</a-button
          >&nbsp;
          <a-button
            @click="refresh"
            type="default"
            icon="reload"
            :loading="loading"
            >刷新</a-button
          >
        </div>
        <div
          style="
            background: #fff;
            padding-left: 16px;
            height: 100%;
            margin-top: 5px;
          "
        >
          <a-alert type="info" :showIcon="true">
            <div slot="message">
              当前选择：
              <span v-if="this.currSelected.deptName" style="color: black">{{
                getCurrSelectedTitle()
              }}</span>
              <a
                v-if="this.currSelected.deptName"
                style="margin-left: 10px; color: #1890ff"
                @click="onClearSelected"
                >取消选择</a
              >
            </div>
          </a-alert>
          <a-input-search
            @search="onSearch"
            style="width: 100%; margin-top: 10px"
            placeholder="请输入部门名称"
          />
          <!-- 树-->
          <a-row>
            <template>
              <a-dropdown
                :trigger="[this.dropTrigger]"
                @visibleChange="dropStatus"
              >
                <span style="user-select: none">
                  <a-tree
                    v-if="!loading"
                    ref="tree2"
                    style="height: 50vh; overflow-x: hidden; overflow-y: auto"
                    multiple
                    @select="onSelect"
                    @check="onCheck"
                    @rightClick="rightHandle"
                    :selectedKeys="selectedKeys"
                    :load-data="onLoadData"
                    :checkedKeys="checkedKeys"
                    :treeData="departTree"
                    :checkStrictly="true"
                    :expandedKeys="iExpandedKeys"
                    :replace-fields="{
                      children: 'children',
                      key: 'id',
                      value: 'deptName',
                      title: 'deptName',
                    }"
                    :autoExpandParent="autoExpandParent"
                    @expand="onExpand"
                  />
                </span>
                <!--新增右键点击事件,和增加添加和删除功能-->
                <a-menu slot="overlay">
                  <a-menu-item @click="handleAdd(3)" key="1">添加</a-menu-item>
                  <a-menu-item @click="handleDelete" key="2">删除</a-menu-item>
                  <a-menu-item @click="closeDrop" key="3">取消</a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
          </a-row>
        </div>
      </a-card>
    </a-col>
    <a-col :md="12" :sm="24">
      <a-card title="编辑" :bordered="false" style="padding-top: 84px">
        <a-form :form="form">
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="部门名称"
          >
            <a-input
              placeholder="请输入部门/部门名称"
              v-decorator="[
                'deptName',
                {
                  rules: [{ required: true, message: '请输入部门/部门名称!' }],
                },
              ]"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="上级部门"
            v-show="isShow"
          >
            <a-tree-select
              style="width:100%"
              :dropdownStyle="{maxHeight:'200px',overflow:'auto'}"
              :treeData="treeData"
              v-model="model.deptName"
              placeholder=""
              :disabled="true"
            >
            </a-tree-select>
          </a-form-item>
          <!-- <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="部门编码"
          >
            <a-input
              placeholder="请输入部门编码"
              v-decorator="['deptNameEn', { rules: [{ required: true, message: '请输入部门编码!' }] } ]"
            />
          </a-form-item> -->
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="排序"
          >
            <a-input-number
              v-decorator="[
                'deptOrder',
                { rules: [{ required: true, message: '请输入排序' }] },
              ]"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="备注"
          >
            <a-input
              placeholder="备注"
              v-decorator="[
                'remark',
                { rules: [{ required: false, message: '请输入部门编码!' }] },
              ]"
            />
          </a-form-item>

          <!-- <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol"
            label="部门类型">
            <a-select placeholder="请选择部门类型"
              v-decorator="[ 'deptType', { rules: [{ required: true, message: '请选择部门类型' }] } ]">
              <a-select-option v-for="(item,key) in deptTypeSel" :key="key"
                :value="item.value">{{ item.text}}
              </a-select-option>
            </a-select>
          </a-form-item> -->
        </a-form>
        <div class="anty-form-btn" style="float: right">
          <a-button
            @click="emptyCurrForm"
            type="default"
            htmlType="button"
            icon="sync"
            >重置</a-button
          >&nbsp;
          <a-button
            @click="submitCurrForm"
            type="primary"
            htmlType="button"
            icon="form"
            >修改并保存</a-button
          >
        </div>
      </a-card>
    </a-col>
    <depart-modal
      ref="departModal"
      @ok="loadTree"
      @refresh="refresh"
    ></depart-modal>
  </a-row>
</template>
<script>
import DepartModal from "./DepartModal";
import api from "./api";
export default {
  name: "dept",
  components: {
    DepartModal,
  },
  data() {
    return {
      isShow: true,
      deptClassSel: [],
      deptLevelSel: [],
      deptTypeSel: [],
      iExpandedKeys: [],
      loading: false,
      autoExpandParent: true,
      treeData: [],
      departTree: [],
      departTreeCopy: [],
      rightClickSelectedKey: {},
      model: {},
      dropTrigger: "",
      checkedKeys: [],
      selectedKeys: [],
      currSelected: {},
      form: this.$form.createForm(this),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      validatorRules: {
        departName: {
          rules: [{ required: true, message: "请输入部门/部门名称!" }],
        },
        orgCode: { rules: [{ required: true, message: "请输入部门编码!" }] },
        mobile: { rules: [{ validator: this.validateMobile }] },
      },
      node2: {},
      url: {
        delete: "/upms/dept/del",
        edit: "/upms/dept/update",
        deleteBatch: "/sys/sysDepart/deleteBatch",
        exportXlsUrl: "sys/sysDepart/exportXls",
      },
    };
  },

  methods: {
    loadData() {
      this.refresh();
      // this.initDictData()
    },
    // initDictData () {
    //   api.ajaxGetDictItems({ dicKind: 'dept_type' }).then((res) => {
    //     if (res.status === 200) {
    //       this.deptTypeSel = res.data.map(res => { return { value: res.dicCode, text: res.dicDesc } })
    //     }
    //   })
    // },
    loadTree() {
      this.$nextTick(() => {
        api.queryDepartTreeList().then((res) => {
          if (res.data.length > 0) {
            this.departTree = res.data;
          } else {
            this.$message.warning("您没有该权限！");
          }
          this.loading = false;
          this.departTreeCopy = this.departTree;
        });
      });
    },
    onLoadData(treeNode) {
      return new Promise((resolve) => {
        if (treeNode.dataRef.children) {
          api
            .queryDepartTreeList({ parentId: treeNode.dataRef.id })
            .then((res) => {
              if (res.data.length > 0) {
                treeNode.dataRef.children = res.data;
              } else {
              }
              this.loading = false;
            });
          resolve();
          return;
        }
        setTimeout(() => {
          this.departTree = [...this.departTree];
          resolve();
        }, 1000);
      });
    },
    refresh() {
      this.loading = true;
      this.loadTree();
    },
    // 右键操作方法
    rightHandle(node) {
      this.dropTrigger = "contextmenu";
      this.rightClickSelectedKey = node.node.dataRef;
    },
    onExpand(expandedKeys) {
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.iExpandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    // 右键点击下拉框改变事件
    dropStatus(visible) {
      if (visible === false) {
        this.dropTrigger = "";
      }
    },
    // 右键店家下拉关闭下拉框
    closeDrop() {
      this.dropTrigger = "";
    },
    batchDel: function () {
      if (this.checkedKeys.length <= 0) {
        this.$message.warning("请选择一个部门单位！");
      } else {
        var ids = "";
        for (var a = 0; a < this.checkedKeys.length; a++) {
          ids += this.checkedKeys[a] + ",";
        }
        var that = this;
        this.$confirm({
          title: "确认删除",
          content:
            "确定要删除所选中的 " +
            this.checkedKeys.length +
            " 条数据，以及子节点数据吗?",
          cancelText: "取消",
          okText: "确定",
          onOk: function () {
            api.deleteAction({ ids: ids }).then((res) => {
              that.$message.success(res.message);
              that.loadTree();
              that.onClearSelected();
            });
          },
        });
      }
    },
    onSearch(value) {
      const that = this;
      if (value) {
        api.searchByKeywords({ keyWord: value }).then((res) => {
          that.departTree = [];
          for (let i = 0; i < res.data.length; i++) {
            const temp = res.data[i];
            that.departTree.push(temp);
          }
        });
      } else {
        this.iExpandedKeys = [];
        this.loading = true;
        this.departTree = this.departTreeCopy;
        this.loading = false;
      }
    },
    async onCheck(checkedKeys, info) {
      this.checkedKeys = checkedKeys.checked;
    },
    onSelect(selectedKeys, e) {
      const record = e.node.dataRef;
      this.currSelected = Object.assign({}, record);
      this.selectedKeys = [record.key];
      this.setValuesToForm(record);
    },
    // 触发onSelect事件时,为部门树右侧的form表单赋值
    setValuesToForm(record) {
      this.$nextTick(() => {
        setTimeout(() => {
          api.getDeptId(record.id).then((res) => {
            this.form.setFieldsValue(res.data);
          });
          api.getPId(record.parentId).then((res) => {
            if (res.data === null) {
              this.model.deptName = "无";
            } else {
              this.model = res.data;
            }
          });
        }, 50);
      });

      this.form.getFieldDecorator("fax", { initialValue: "" });
    },
    getCurrSelectedTitle() {
      return !this.currSelected.deptName ? "" : this.currSelected.deptName;
    },
    onClearSelected() {
      this.checkedKeys = [];
      this.currSelected = {};
      this.form.resetFields();
      this.selectedKeys = [];
    },
    submitCurrForm() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.currSelected.id) {
            this.$message.warning("请点击选择要修改部门!");
            return;
          }
          const formData = Object.assign(this.currSelected, values);
          api.edit(formData).then((res) => {
            this.$message.success(res.message);
            this.refresh();
          });
        }
      });
    },
    emptyCurrForm() {
      this.form.resetFields();
    },
    handleAdd(num) {
      if (num === 1) {
        this.$refs.departModal.add();
        // this.$refs.departModal.initDictData()
        this.$refs.departModal.title = "新增";
      } else if (num === 2) {
        const key = this.currSelected.id;
        if (!key) {
          this.$refs.departModal.add();
          this.$nextTick(() => {
            // this.$refs.departModal.initDictData()
          });
          return false;
        }
        this.$refs.departModal.add(this.currSelected, "Son");
        this.$nextTick(() => {
          // this.$refs.departModal.initDictData()
        });

        this.$refs.departModal.title = "新增";
      } else {
        this.$refs.departModal.add(this.rightClickSelectedKey, "father");
        this.$refs.departModal.title = "新增";
      }
    },
    handleTopDelete() {
      console.log(this.selectedKeys, "selectedKeys");
      if (this.selectedKeys.length <= 0) {
        this.$message.warning("请选择部门");
        return;
      }
      this.$confirm({
        title: "是否删除",
        content: "确认是否删除当前部门以及子部门下用户?",
        cancelText: "取消",
        okText: "确定",
        onOk: () => {
          api.delete({ id: this.selectedKeys[0] }).then((res) => {
            if (res.status === 200) {
              this.$message.success(res.message);
              // this.loadTree()
              this.refresh();
              this.onClearSelected();
            }
          });
        },
      });
    },
    async handleDelete() {
      const count = await this.ifUserDeptCount();
      if (count > 0) {
        this.$message.warning(
          "该部门下还存在人员，请先进行人员迁移在进行删除操作！"
        );
        return;
      }
      var that = this;
      this.$confirm({
        title: "是否删除",
        content: "确认是否删除当前部门以及子部门下用户?",
        cancelText: "取消",
        okText: "确定",
        onOk: function () {
          api.delete({ id: that.rightClickSelectedKey.id }).then((res) => {
            if (res.status === 200) {
              that.$message.success(res.message);
              // this.loadTree()
              that.refresh();
              that.onClearSelected();
            }
          });
        },
      });
    },
    async ifUserDeptCount() {
      const parm = {};
      parm.crChargeDept = this.rightClickSelectedKey.id;
      const res = await api.listCheck({ deptId: parm.crChargeDept });
      return res.data.total;
    },
  },
  created() {
    this.loadData();
  },
};
</script>
